<template>
  <div class="search-bar-or">
    <div class="search-bar"> 
      <TreeSelect 
        class="device" 
        v-model="selectedDevices" 
        :title="$t('search_device') /*設備*/"
        :data="deviceTreeList" 
        :groupList="groupList"  
        :isGroupSelect="true"
        :isMultipleSelect="true"
        optionWidth="150%"
      />
      <TreeSelect 
        class="device" 
        v-model="selectedGroup" 
        :title="$t('history_device_group') /*設備群組*/"
        :data="deviceGroupList" 
        :groupList="groupList"  
        :isGroupSelect="true"
        :isMultipleSelect="false"
        :defaultProps="deviceGroupProps"
        optionWidth="150%"
      />
    
      <DateRangeSelect 
        class="dt-select" 
        v-model="orDateTime" 
        :range="true"
        :title="$t('search_time_interval')" 
        :maxRangeDays="maxRangeDays"
        :enabledDateStart="enabledDateStart" 
        :enabledDateEnd="enabledDateEnd" 
        :shortcuts="shortcuts"
      />
      <MagnifierSearchBtn 
        :active="searchLoading"
        @click="onSearchOrEvents()" 
      />
      <el-tooltip popper-class="el-tooltip" effect="dark" v-delTabIndex :visible-arrow="false" :content="$t('search_clear')/*清除*/" placement="bottom">
        <div class="tool" @click="clearSearchParams">
          <img src="@/assets/icons/erase-white.svg" alt="">
        </div>
      </el-tooltip> 
    </div>
    <Pagination
      v-if="orEventList.length > 0"
      class="pagination"
      :currentPage="orCurrentPage"
      :total="orEventsTotal"
      :pageSize="orPageSize" 
      mode="multiple"
      @getPageData="getPageEvents"
    ></Pagination>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import { getOrDateRangeSetting } from '@/config/dateRange.js'

export default {
  name: 'SearchBarOr',
  components: {
    TreeSelect: () => import('@/components/Base/TreeSelect.vue'),
    DateRangeSelect: () => import('@/components/Base/DateRangeSelect.vue'),
    MagnifierSearchBtn: () => import('@/components/Base/MagnifierSearchBtn.vue'),
    Pagination: () => import('@/components/Base/Pagination.vue'),
  },
  data() {
    return {
      selectedDevices: [],
      orDateTime: [], // 緊急事件時間區間
      maxRangeDays: 90, // 最大可選擇區間天數
      enabledDateStart: null, // 可以查詢開始時間
      enabledDateEnd: null, // 可以查詢結束時間
      shortcuts: [ // 快速選擇時間區間
        { text: this.$t('today'), onClick: () => {
            // 今天 00:00 ~ 23:59
            const date = new Date()
            const startOfDay = new Date(date.getFullYear(), date.getMonth(), date.getDate())
            const endOfDay = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59)
            return [ startOfDay, endOfDay ]
          }
        },
        { text: this.$t('yesterday'), 
          onClick: () => {
            // 昨天 00:00 ~ 昨天 23:59
            const date = new Date()
            const startOfDay = new Date(date.getFullYear(), date.getMonth(), date.getDate() - 1)
            const endOfDay = new Date(date.getFullYear(), date.getMonth(), date.getDate() - 1, 23, 59, 59)
            return [ startOfDay, endOfDay ]
          }
        },
        { text: this.$t('last_week'), 
          onClick: () => {
            // 7天前 00:00 ~ 今天 23:59
            const date = new Date()
            const startOfDay = new Date(date.getFullYear(), date.getMonth(), date.getDate() - 6)
            const endOfDay = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59)
            return [ startOfDay, endOfDay ]
          }
        },
        { text: this.$t('last_month'), 
          onClick: () => {
            // 30天前 00:00 ~ 今天 23:59
            const date = new Date()
            const startOfDay = new Date(date.getFullYear(), date.getMonth(), date.getDate() - 29)
            const endOfDay = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59)
            return [ startOfDay, endOfDay ]
          }
        },
        { text: this.$t('last_three_months'), 
          onClick: () => {
            // 過去三個月 00:00 ~ 今天 23:59
            const date = new Date()
            const startOfDay = new Date(date.getFullYear(), date.getMonth(), date.getDate() - 89)
            const endOfDay = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59)
            return [ startOfDay, endOfDay ]
          }
        }
      ], 

      selectedGroup: {}, // 選中的群組
      deviceGroupList: [], // 設備群組列表
      deviceGroupProps: {
        children: 'children',
        label: 'label',
      },
    }
  },
  computed: {
    ...mapState(['groupList', 'groupTree']),
    ...mapState('account', ['deviceTreeList']),
    ...mapState('historyOr', [
      'searchLoading', 
      'orEventList',
      'orPageSize',
      'orEventsTotal',
      'orCurrentPage',
    ]),
  },
  // watch: {
  //   urgMode() {
  //     this.clearSearchParams()
  //     this.initDateRange()
  //   },
  // },
  async mounted() {
    // 0: private, 1: public
    await this.getDeviceTreeList({ public: 0 })
    
    const deviceData = JSON.parse(JSON.stringify(this.deviceTreeList))
    this.deviceGroupList = this.removeEmptyChildren(deviceData)
    this.initDateRange()
  },
  methods: {
    ...mapMutations('historyOr', [ 'updateSearchParams']),
    ...mapActions('account', ['getDeviceTreeList']),
    ...mapActions('historyOr', ['getOrEventsTotal', 'searchOrEvents', 'resetHistoryOrData']),
    initDateRange() {
      // 物件事件預設搜尋區間: 預設1週 
      // 物件事件最大可搜尋區間:預設最近6個月
      // 物件事件最遠可搜尋的範圍: 預設最近1年
      const { defaultDateRange, maxRangeDays, enabledDateStart, enabledDateEnd } = getOrDateRangeSetting()
      this.orDateTime = defaultDateRange
      this.maxRangeDays = maxRangeDays
      this.enabledDateStart = enabledDateStart
      this.enabledDateEnd = enabledDateEnd
    },
    sortflattenTree(obj) {
      if (obj.group) {
        let flattened = { ...obj.group }
        if (obj.children) {
          let sortChildren = obj.children.sort((a, b) => a.group.name.localeCompare(b.group.name))
          flattened.children = sortChildren.map(child => this.sortflattenTree(child))
        }
        return flattened
      } else {
        return obj
      }
    },
    getSearchParams() {
      const param = {}
      // 設備
      if (this.selectedDevices.length > 0) {
        param.user = this.selectedDevices.map(item => item.id) 
      }

      // 設備群組
      if (this.selectedGroup.groupId) {
        param.group = [this.selectedGroup.groupId]
      }

      // 時間區間
      if (this.orDateTime.length == 2) {
        param['afterDetectTime'] = new Date(this.orDateTime[0]).toISOString()
        param['beforeDetectTime'] = new Date(this.orDateTime[1]).toISOString()
      }
      
      return param
    },
    onSearchOrEvents() {
      const params = this.getSearchParams()
      this.updateSearchParams(params)
      
      this.getOrEventsTotal()
      this.searchOrEvents({ page: 1 })
    },
    clearSearchParams() {
      this.selectedDevices = []
      this.selectedGroup = {}
      this.orDateTime = []
      this.initDateRange()
    },
    async getPageEvents(page) {
      if (page === 0) {
        this.$notify({
          title: this.$t('search_hint')/* 提示 */,
          message: '已在第一頁',
          type: 'warning'
        })
        return
      }

      await this.searchOrEvents({ page: page })
    },
    removeEmptyChildren(data) {
      return data.filter(node => {
        // 節點有children且長度不為0
        if (node.children && node.children.length > 0) {
            node.children = this.removeEmptyChildren(node.children)
            return true
        }
        return node.children
      })
    }
  },
}
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}

.search-bar-or {
  width: 100%;
  height: 134px;
  color: #ffffff;
  background: #282942;
  padding: px2rem(8) px2rem(20);
  border-bottom: 1px solid #ffffff33;

  .search-bar {
    display: flex;
    align-items: flex-end; 
    column-gap: px2rem(12);
    margin-bottom: 8px;
    
    .device {
      width: 20%;
    }

    .dt-select {
      width: 30%;
    }
  }
}

.wrap-btn-search {
  display: flex;
  align-items: flex-end;
}

.tool {
  width: px2rem(36);
  height: px2rem(36);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    background: #FFFFFF1A;
    border-radius: 8px;
  }
  &:active {
    background: #FFFFFF80;
  }
  img {
    width: px2rem(22);
    height: px2rem(22);
  }
}

.pagination {
  padding: px2rem(8) 0;
}
</style>